<!-- 病房智能交互系统 -->
<template>
  <div class="wrapper pageBox">
    <!-- header -->
    <wardHeader from="ward" />
    <!-- 产品亮点 -->
    <sectionTitle
      title-c-n="产品亮点"
      title-e-n="Product Highlights"
      :highlight-list="highlightList"
    />
    <!-- 产品介绍 -->
    <sectionTitle
      title-c-n="产品介绍"
      title-e-n="product description"
    />
    <!-- 产品介绍 -->
    <description from="ward" />
  </div>
</template>

<script>
import wardHeader from './components/header.vue';
import sectionTitle from './components/sectionTitle.vue';
import description from './components/description.vue';
export default {
  name: 'SmartWard',
  components: {
    wardHeader,
    sectionTitle,
    description
  },
  data() {
    return {
      highlightList: [{
        title: '多终端病呼联动',
        img: require('@/assets/solution/highlight.png'),
        text: '整合行业领先的病呼设备，提供可靠、稳定的呼叫通讯。支持床头屏、门口屏、护士手表、智能手环、走廊等多终'
      }, {
        title: '患者信息实时更新',
        img: require('@/assets/solution/highlight.png'),
        text: '动态显示患者基本信息、医护信息、护理等级标识、提醒信息、饮食信息、欠费信息、检查手术提醒信息及各种护理标牌。同时可在床头屏上进行医院宣传查看、服务评价、费用查询、体征查询等操作'
      }, {
        title: '健康宣教实时推送',
        img: require('@/assets/solution/highlight.png'),
        text: '管理后台向床头卡、门口屏、护士站大屏更新视频宣教信息，减轻护士每日宣教工作量，患者可以第一时间观看宣教视频快速掌握保健知识'
      }]
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {

  }
};
</script>

<style lang='scss' scoped>

</style>
